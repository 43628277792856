import React from 'react'
import { Link } from 'gatsby'
import logo from '../../images/logo.png'
import Sidebar from 'react-sidebar'
import menulogo from '../../images/menu.png'
import phone from '../../images/smartphone.png'
import Sticky from 'react-sticky-el'
import Nav from '../nav'

class Header extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			sidebarOpen: false,
		}
		this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this)
	}

	onSetSidebarOpen(open) {
		this.setState({ sidebarOpen: open })
	}
	render() {
		return (
			<Sticky className="sticky-wrapper">
				{
					<div className="menu-container py-3">
						<Sidebar
							sidebar={<Nav />}
							open={this.state.sidebarOpen}
							onSetOpen={this.onSetSidebarOpen}
							styles={{ sidebar: { background: 'white' } }}
							sidebarId="sidenav"
							pullRight={true}
						>
							<div className="container">
								<div className="row d-flex align-items-center">
									<div className="col-8 col-md-4">
										<div className="nav-brand">
											<Link to="/" title="Home" className="p-0">
												<img
													className="img-fluid mb-0 mt-1"
													alt="Logo"
													src={logo}
												/>
											</Link>
										</div>
									</div>
									<div className="col-4 col-md-8 ml-auto d-flex justify-content-end">
										<div className="nav_content ml-3">
											<div className="tel">
												<img
													src={phone}
													className="img-fluid mr-2"
													alt="Phone Icon"
												/>
												<a href="tel:7607165585" className="contact-link">
													(760) 716-5585
												</a>
											</div>
											<h2 className="float-right nav_handle mb-0">
												<img
													alt="Menu"
													className="mb-0"
													src={menulogo}
													onClick={() => this.onSetSidebarOpen(true)}
													style={{ cursor: 'pointer' }}
												/>
											</h2>
										</div>
									</div>
								</div>
							</div>
						</Sidebar>
					</div>
				}
			</Sticky>
		)
	}
}

export default Header
