import React from 'react'
import { Link } from 'gatsby'
import style from './index.module.css'
import logo from '../../images/logo.png'
const Nav = ({ menuItems }) => (
  <nav className={style.nav}>
    <div className="container">
      <div className="nav-brand-side my-4">
        <img className="img-fluid" alt="Logo" src={logo} />
      </div>
      <ul className="list-unstyled main-nav mr-0">
        <li>
          <Link title="Home" to="/" className="link p-0">
            Home
          </Link>
        </li>
        <li>
          <Link title="Solar Shingles" to="/#about-us" className="link p-0">
            About Us
          </Link>
        </li>
        <li>
          <Link title="Gaf" to="/lunch-truck-advertising" className="link p-0">
            Lunch Truck Advertising
          </Link>
        </li>
        <li>
          <Link
            title="Windows"
            to="/storefront-advertising"
            className="link p-0"
          >
            In-Store Advertising
          </Link>
        </li>
        <li>
          <Link title="Gaf" to="/urban-panel" className="link p-0">
            Urban Panels
          </Link>
        </li>
        <li>
          <Link title="Gaf" to="/bill-poster/" className="link p-0">
            Bill Posters
          </Link>
        </li>
        <li>
          <Link title="Gaf" to="/customized-psa-campaigns" className="link p-0">
            Customized PSA Campaigns
          </Link>
        </li>
        {/* <li>
					<Link
						title="Gaf"
						to="/in-memory-of-david-braunstein"
						className="link p-0"
					>
						In memory of David Braunstein
					</Link>
				</li> */}
        <li>
          <Link title="Roofing" to="/#clients" className="link p-0">
            Our Clients
          </Link>
        </li>
        <li>
          <Link title="Warranty" to="/#contact" className="link p-0">
            Contact Us
          </Link>
        </li>
      </ul>
      <p className="text-black mt-4">
        All rights Reserved, Trimex Mobile Marketing - Trimex Outdoor 2023
      </p>
    </div>
  </nav>
)
export default Nav
