import React from 'react'
import style from './index.module.css'

const Footer = ({ siteTitle }) => (
  <footer className={style.footer}>
    <div id="footer">
        <div className="container">
            <div className="row">
                <div className="col d-flex justify-content-between">
                    <p className="footer-p">© Copyright {new Date().getFullYear()} Trimex Mobile Marketing - Trimex Outdoor</p>
                    <p className="footer-p">All Rights Reserved - Designed and Powered by <a href="http://webriq.com" target="_blank" rel="nofollow noreferrer" title="WebriQ" className="text-white">WebriQ</a></p>
                </div>
            </div>
        </div>
    </div>
  </footer>
)

export default Footer
